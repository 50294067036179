<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form>
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <!-- current password -->
              <v-text-field
                v-model="currentPassword"
                :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Current Password"
                outlined
                dense
                :class="formErrorFields.currentPasswordErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.currentPasswordErrorMessage"
                @keydown="formError().remove('currentPassword', formErrorFields)"
                @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
              ></v-text-field>

              <!-- new password -->
              <v-text-field
                v-model="password"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="New Password"
                outlined
                dense
                :class="formErrorFields.passwordErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.passwordErrorMessage"
                @keydown="formError().remove('password', formErrorFields)"
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="confirmPassword"
                :type="isConfirmPasswordVisible ? 'text' : 'password'"
                :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Confirm New Password"
                outlined
                dense
                @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
              <v-img
                contain
                max-width="170"
                src="@/assets/images/3d-characters/pose-m-1.png"
                class="security-character"
              ></v-img>
            </v-col>
          </v-row>

          <v-btn
            color="primary"
            class="me-3 mt-3"
            :loading="loading"
            @click.prevent="submit"
          >
            Save Changes
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mt-3"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </div>

      <!-- divider -->
      <v-divider></v-divider>

      <div class="pa-3">
        <v-card-title class="flex-nowrap">
          <v-icon class="text--primary me-3">
            {{ icons.mdiKeyOutline }}
          </v-icon>
          <span class="text-break">Two-factor authentication</span>
        </v-card-title>

        <v-card-text class="two-factor-auth text-center mx-auto">

          <v-btn
            class="mb-4"
            color="primary"
            @click="showConfirmEnableOrDisable2FADialog=true"
            :loading="loading2FA"
            :title="TFA === 1 ? 'Disable two factor authentication' : 'Enable two factor authentication'"
          >
              <v-icon>{{ TFA === 1 ? icons.mdiLockOffOutline : icons.mdiLockOpenOutline }}</v-icon>
          </v-btn>
          <p
            class="text-base text--primary font-weight-semibold"
            v-if="TFA === 0">
            Two factor authentication is not enabled yet.
          </p>
          <p
            class="text-base text--primary font-weight-semibold"
            v-else
          >
            Two factor authentication is enabled.
          </p>
          <div v-if="showGoogle2FAQRCode">
            Scan the following QR code using your phone's authenticator application.
            <div class="mt-4" v-html="google2FAQRCode"></div>
          </div>
          <p class="text-sm text--primary">
            Two-factor authentication adds an additional layer of
            security to your account by requiring more than just a
            password to log in.
          </p>
        </v-card-text>
      </div>

      <!-- divider -->
      <v-divider></v-divider>

      <div class="pa-3">
        <v-card-title class="flex-nowrap">
          <v-icon class="text--primary me-3">
            {{ icons.mdiGoogleChrome }}
          </v-icon>
          <span class="text-break">Browser Sessions</span>
        </v-card-title>

        <v-card-text class="two-factor-auth text-center mx-auto">
          <v-btn
            color="primary"
            class="primary mb-4"
            @click.prevent="showConfirmLogoutSessionDialog=true"
          >
            Logout other browser sessions
          </v-btn>
          <p class="text-sm text--primary">
            If nescessary, you may logout of all of your other browser sessions accross all of your devices.
            If you feel your account has been compromise, you should also update your password.
          </p>
        </v-card-text>
      </div>
    </v-form>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    >
    </snackbar>

    <confirm-password
      :show="showConfirmEnableOrDisable2FADialog"
      @cancel="showConfirmEnableOrDisable2FADialog=false"
      @execute="enableOrDisable2FA">
    </confirm-password>

    <confirm-password
      :show="showConfirmLogoutSessionDialog"
      @cancel="showConfirmLogoutSessionDialog=false"
      @execute="logoutOtherBrowserSession">
    </confirm-password>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline, mdiGoogleChrome, mdiLockOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import crud from '@/composables/crud'
import get from '@/composables/get'
import formError from '@/composables/formErrorHandler/formError'
import snackbar from '@/components/SnackBar.vue'
import fnx from '@/functions/fn'
import ConfirmPassword from '@/components/ConfirmPassword.vue'

export default {
  components: {
    snackbar,
    ConfirmPassword,
  },

  setup() {
    const showConfirmLogoutSessionDialog = ref(false)
    const showConfirmEnableOrDisable2FADialog = ref(false)
    const showGoogle2FAQRCode = ref(false)
    const google2FAQRCode = ref(false)
    const loadingLogoutBrowserSession = ref(false)
    const loading = ref(false)
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)
    const currentPassword = ref('')
    const password = ref('')
    const confirmPassword = ref('')
    const formErrorFields = ref({
      currentPasswordErrorMessage: '',
      passwordErrorMessage: '',
      confirmPasswordErrorMessage: '',
    })
    const TFA = ref(0)
    const loading2FA = ref(false)

    const {
      store,
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const submit = async () => {
      const formData = {
        currentPassword: currentPassword.value,
        password: password.value,
        confirmPassword: confirmPassword.value,
      }

      const uri = `${process.env.VUE_APP_URI}/api/payroll/user-accounts/password/update`
      await update(uri, loading, formData, formErrorFields)
      if (responseMessageStatus.value === 'success') {
        currentPassword.value = ''
        password.value = ''
        confirmPassword.value = ''
      }
    }

    // check 2FA
    const checkFor2FA = async () => {
      await get(`${process.env.VUE_APP_URI}/api/payroll/check-two-factor-authentication`, TFA, loading2FA)
    }

    checkFor2FA()

    // enable 2FA
    const enableOrDisable2FA = async () => {
      await update(`${process.env.VUE_APP_URI}/api/payroll/${TFA.value === 1 ? 'disable-2FA' : 'enable-2FA'}`, loading2FA, {}, {}, google2FAQRCode)
      if (responseMessageStatus.value === 'success') {
        await checkFor2FA()
        if (TFA.value === 1) {
          google2FAQRCode.value = fnx.base64_decode(google2FAQRCode.value.qrcode)
          showGoogle2FAQRCode.value = true
        } else {
          showGoogle2FAQRCode.value = false
        }
      }

      setTimeout(() => {
        showGoogle2FAQRCode.value = false
      }, 180000)
    }

    // logout all sessions
    const logoutOtherBrowserSession = async () => {
      await store(`${process.env.VUE_APP_URI}/api/payroll/logout-all-sessions`, loadingLogoutBrowserSession)
    }

    return {
      showConfirmLogoutSessionDialog,
      showConfirmEnableOrDisable2FADialog,
      google2FAQRCode,
      showGoogle2FAQRCode,
      TFA,
      loading2FA,
      enableOrDisable2FA,
      formErrorFields,
      formError,
      loading,
      submit,
      logoutOtherBrowserSession,
      loadingLogoutBrowserSession,
      responseMessage,
      responseMessageStatus,
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isConfirmPasswordVisible,
      password,
      confirmPassword,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiLockOffOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiGoogleChrome,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
