<template>
  <v-card
    flat
    class="pa-3 mt-2"
    :loading="userLoading"
  >
    <v-card-text class="d-flex">
      <v-avatar
        rounded
        size="120"
        class="me-6"
      >
        <v-img :src="imageSource"></v-img>
      </v-avatar>

      <!-- upload photo -->
      <div>
        <v-btn
          color="primary"
          class="me-3 mt-5"
          @click="$refs.image.click()"
        >
          <v-icon class="d-sm-none">
            {{ icons.mdiCloudUploadOutline }}
          </v-icon>
          <span class="d-none d-sm-block">Upload new photo</span>
        </v-btn>

        <input
          id="image"
          ref="image"
          type="file"
          accept=".jpeg,.png,.jpg,GIF"
          :hidden="true"
          @change="onImageChange"
        />

        <v-btn
          color="error"
          outlined
          class="mt-5"
          @click="resetImage"
        >
          Reset
        </v-btn>
        <p class="text-sm mt-5">
          Allowed JPG, GIF or PNG. Max size of 800K
        </p>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              :value="$store.state.user.user.name"
              label="Name"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="username"
              label="Username"
              dense
              outlined
              :class="formErrorFields.usernameErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.usernameErrorMessage"
              @keydown="formError().remove('username', formErrorFields)"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="email"
              label="E-mail"
              dense
              outlined
              :class="formErrorFields.emailErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.emailErrorMessage"
              @keydown="formError().remove('email', formErrorFields)"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              @click.prevent="submit"
            >
              Save changes
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import crud from '@/composables/crud'
import get from '@/composables/get'
import formError from '@/composables/formErrorHandler/formError'
import snackbar from '@/components/SnackBar.vue'
import fnx from '@/functions/fn'

export default {
  components: {
    snackbar,
  },

  setup(props, { root }) {
    const userLoading = ref(false)
    const loading = ref(false)
    const userInfo = ref(null)
    const imageSource = ref(null)
    const username = ref('')
    const email = ref('')
    const image = ref(null)
    const formErrorFields = ref({
      usernameErrorMessage: '',
      emailErrorMessage: '',
    })

    username.value = root.$store.state.user.user.username
    email.value = root.$store.state.user.user.email

    const {
      store,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const submit = async () => {
      const formData = {
        username: username.value,
        email: email.value,
      }

      const form = new FormData()
      const fileImage = image.value.files[0]
      form.append('image', fileImage !== undefined ? fileImage : '')

      fnx.buildFormData(form, formData)

      const uri = `${process.env.VUE_APP_URI}/api/payroll/user-accounts/info/update`
      await store(uri, loading, form, formErrorFields)
    }

    const getInfo = async () => {
      await get(`${process.env.VUE_APP_URI}/api/payroll/user-accounts/info`, userInfo, userLoading)
      username.value = userInfo.value.username
      email.value = userInfo.value.email
      imageSource.value = userInfo.value.image !== '' ? `${process.env.VUE_APP_URI}/image/${fnx.base64_encode(userInfo.value.image)}/payroll_users` : null
    }

    const resetImage = () => {
      imageSource.value = userInfo.value.image !== '' ? `${process.env.VUE_APP_URI}/image/${fnx.base64_encode(userInfo.value.image)}/payroll_users` : null
    }

    getInfo()

    const onImageChange = e => {
      const file = e.target.files[0]
      imageSource.value = URL.createObjectURL(file)
    }

    return {
      userLoading,
      loading,
      submit,
      imageSource,
      resetImage,
      username,
      email,
      image,
      formError,
      formErrorFields,
      responseMessage,
      responseMessageStatus,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
      onImageChange,
    }
  },
}
</script>
